import { FC, useEffect, useMemo, useState } from 'react';

import { Card, CardHeader, CardContent } from '@components/layouts/Card';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { DataTable } from '@components/elements/Datatable';

import api from '@services/bbankApi';

const ListNominalCard: FC = () => {
  const [listCards, setListCards] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    async function loadListCardNominal() {
      const { data } = await api.get('/users/cards', {
        params: {
          page,
          records: perPage,
        },
      });

      const parsedCard = data.cards.rows.map((item: any) => ({
        name: item.name,
        status: item.status,
        transaction_limit: item.transaction_limit,
        type: item.type,
      }));

      setListCards(parsedCard);
      setTotalRecords(data.cards.count);
    }

    loadListCardNominal();
  }, [page, perPage]);

  const tableData = useMemo(() => {
    return listCards || [];
  }, [listCards]);

  const tableColumns = useMemo(
    () => [
      {
        name: 'Name',
        selector: 'name',
      },
      {
        name: 'Limite de transação',
        selector: 'transaction_limit',
      },
      {
        name: 'Tipo',
        selector: 'type',
      },
      {
        name: 'Status',
        selector: 'status',
      },
    ],
    [],
  );

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePerRowsChange = (newPerPage: number, newPage: number) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  return (
    <>
      <Row>
        <URLPath paths={['Cartões', 'Cartões Nominais Gerados']} />
      </Row>

      <CardContent>
        <DataTable
          columns={tableColumns}
          data={tableData}
          pagination
          paginationServer
          paginationTotalRows={totalRecords}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </CardContent>
    </>
  );
};

export default ListNominalCard;
