import {
  FC,
  useCallback,
  useRef,
  FocusEvent,
  useState,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import {
  FiFlag,
  FiHash,
  FiHome,
  FiMap,
  FiMapPin,
  FiPhone,
  FiSave,
} from 'react-icons/fi';
import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';
import Loader from 'react-loader-spinner';

import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { Badge } from '@components/elements/Badge';

import { useToast } from '@hooks/toast';

import { IZipcodeResponse } from '@services/interfaces';
import viaCepApi from '@services/viaCepApi';
import api from '@services/bbankApi';

import { IOption } from '@utils/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { consoleLog } from '@helpers/consoleLog';

import JSONObj from '../../../../../../../../resources/estados-cidades.json';
import {
  adressType,
  statesOption,
  typeCodStates,
  typePhone,
} from './selectOptions';
import { IAccount, IAddress, IFormData, IParsedData } from './interface';
import { addressFormValidation, formValidation } from './validations';
import { Address } from './styles';

const NewNominalCard: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const addressFormRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [account, setAccount] = useState<IAccount>();
  const [addresses, setAddresses] = useState<IAddress[]>();
  const [cityOptions, setCityOptions] = useState<IOption[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [creatingNewAddress, setCreatingNewAddress] = useState(false);
  const [chosenAddress, setChosenAddress] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadAccount() {
      const { data: responseAccount } = await api.get('/users/cards/account');
      const { data: accountAddresses } = await api.get<IAddress[]>(
        '/users/cards/accounts/addresses',
      );

      const chosen = accountAddresses.find(address => address.mailing_address);

      setAccount(responseAccount);
      setAddresses(accountAddresses);
      setChosenAddress(chosen?.address_id.toString() || '');
    }

    loadAccount();
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      const { cod, phone } = data;

      if (!chosenAddress) {
        addToast({
          title: 'Você esqueceu algo...',
          type: 'info',
          message: 'Selecione o endereço de entrega do seu cartão nominal!',
        })
        return;
      }

      try {
        formRef.current?.setErrors({});

        await formValidation({ cod, phone, type: data.type });

        const phoneIsAlreadyRegistered = account?.phones.some(p => phone === p.phone);

        if (!phoneIsAlreadyRegistered) {
          const formData: IParsedData = {
            number: data.phone,
            areaCode: data.cod,
            type: data.type,
          };

          await api.post(`/users/cards/accounts/phone`, formData);
        }

        await api.post('/users/cards');

        addToast({
          title: 'Dados cadastrados com sucesso!',
          type: 'success',
        });

        reset();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const { message } = getClientErrors(err.response);

        addToast({
          title: 'Algum erro aconteceu!',
          type: 'error',
          message,
        });
      }
    },
    [addToast, chosenAddress, account],
  );

  const handleFindZipCode = useCallback(
    async (e: FocusEvent<HTMLElement>) => {
      const { value } = e.target as HTMLInputElement;

      if (!value) {
        return;
      }

      setButtonLoading(true);

      try {
        const { target } = e as { target: { value?: string } };

        const cep = target.value?.replace('-', '');

        const { data } = await viaCepApi.get<IZipcodeResponse>(`${cep}/json`);

        if (data.erro) {
          addToast({
            title: 'CEP inválido!',
            type: 'info',
          });

          return;
        }

        const {
          cep: zipcode,
          logradouro,
          uf,
          complemento,
          bairro,
          localidade,
        } = data;


        const cityInputRef = addressFormRef.current?.getFieldRef('city');
        const zipcodeInputRef = addressFormRef.current?.getFieldRef('zipCode');
        const neighborhoodInputRef = addressFormRef.current?.getFieldRef(
          'neighborhood',
        );
        const addressInputRef = addressFormRef.current?.getFieldRef('address');
        const stateInputRef = addressFormRef.current?.getFieldRef('state');
        const complementInputRef = addressFormRef.current?.getFieldRef(
          'complementaryAddress',
        );

        cityInputRef.value = localidade;
        neighborhoodInputRef.value = bairro;
        zipcodeInputRef.value = zipcode;
        addressInputRef.value = logradouro;
        complementInputRef.value = complemento;

        const getSelectStateValue = statesOption.find(
          state => state.value.toLowerCase() === uf.toLowerCase(),
        );

        stateInputRef.select.setValue(getSelectStateValue);

        addToast({
          title: 'Endereço preenchido!',
          type: 'success',
        });
      } catch (err: any) {
        addToast({
          title: 'CEP não encontrado!',
          type: 'info',
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  const handleStateFieldBlur = useCallback((option: OptionTypeBase | null) => {
    if (!option) {
      return;
    }

    const { value } = option;

    const state = JSONObj.estados.find(item => item.sigla === value);

    if (!state) {
      setCityOptions([]);
      return;
    }

    const cities = state.cidades;

    const parsedCities = cities.map(city => ({ value: city, label: city }));

    setCityOptions(parsedCities);
  }, []);

  const handleAddressClick = useCallback((radioId: string) => {
    if (loading) return;

    const radio = document.getElementById(radioId) as HTMLInputElement | null;

    if (!radio) return;

    setLoading(true);

    const addressId = radio.value;

    api.put(`/users/cards/accounts/address/${addressId}`, { mailingAddress: true }).then(() => setLoading(false));

    radio.click();
  }, [loading]);

  const handleShowAddressForm = useCallback(() => {
    setCreatingNewAddress(oldState => !oldState);
  }, []);

  const handleCreateNewAddress = useCallback(async (data: IFormData) => {
    try {
      await addressFormValidation(data);

      const request = {
        addressType: data.addressType,
        address: data.address,
        number: data.number,
        neighborhood: data.neighborhood,
        zipCode: data.zipCode,
        city: data.city,
        state: data.state,
        complementaryAddress: data.complementaryAddress,
      };

      const { data: responseData } = await api.post(
        `/users/cards/accounts/addresses`,
        request,
      );

      setAddresses(responseData.account.addresses);
      setCreatingNewAddress(false);

      addToast({
        title: 'Legal!',
        type: 'success',
        message: 'Seu endereço foi adicionado com sucesso!',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        addressFormRef.current?.setErrors(errors);
      }
    }
  }, [addToast]);

  return (
    <>
      <Row>
        <URLPath paths={['Cartão', 'Novo Cartão Nominal']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo Cartão Nominal</h1>
          </CardHeader>

          <CardContent>
            <FormRow separator>
              <h1>Dados para entrega do cartão</h1>
            </FormRow>

            {addresses?.length ? (
              addresses?.map(address => {
                const radioId = `select-address-${address.address_id}`;

                if (creatingNewAddress) return null;

                return (
                  <Address
                    key={address._id}
                    onClick={() => handleAddressClick(radioId)}
                    disabled={loading}
                  >
                    <header>
                      <FiHome size={48} />
                    </header>

                    <main>
                      <p>{`Endereço: ${address.address} ${address.number}, ${address.zip_code}`}</p>
                      <p>{`Cidade: ${address.city}`}</p>
                      <p>{`Estado: ${address.state}`}</p>
                    </main>

                    <footer>
                      {loading && chosenAddress === address.address_id.toString() ? (
                        <Loader type="TailSpin" width={18} height={18} color="#151515" />
                      ) : (
                        <input
                          type="radio"
                          name="option"
                          id={radioId}
                          value={address.address_id}
                          defaultChecked={(address.address_id.toString() === chosenAddress) || undefined}
                          onChange={event => setChosenAddress(event.target.value)}
                        />
                      )}
                    </footer>
                  </Address>
                );
              })
            ) : (
              <Badge type="warning">
                Não há endereços cadastrados para receber cartões
              </Badge>
            )}

            <Button
              styleType={creatingNewAddress ? 'danger' : 'info'}
              onClick={handleShowAddressForm}
              style={{ marginTop: 16, width: '100%', marginBottom: 32 }}
              type="button"
            >
              {creatingNewAddress ? 'Cancelar' : 'Adicionar novo endereço'}
            </Button>

            {creatingNewAddress ? (
              <Form onSubmit={handleCreateNewAddress} ref={addressFormRef}>
                <FormRow>
                  <InputGroup>
                    <label>Tipo de Endereço</label>

                    <Select
                      name="addressType"
                      options={adressType}
                      icon={FiHash}
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>CEP</label>
                    <InputMask
                      name="zipCode"
                      mask="99999-999"
                      placeholder="99999-999"
                      onBlur={e => handleFindZipCode(e)}
                      icon={FiMapPin}
                      noUnmask
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Endereço</label>
                    <Input name="address" icon={FiHome} />
                  </InputGroup>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Número</label>

                    <Input name="number" icon={FiMap} />
                  </InputGroup>

                  <InputGroup>
                    <label>Bairro</label>
                    <Input name="neighborhood" icon={FiHome} />
                  </InputGroup>

                  <InputGroup>
                    <label>Estado</label>
                    <Select
                      name="state"
                      id="state"
                      options={statesOption}
                      onChange={handleStateFieldBlur}
                      icon={FiFlag}
                    />
                  </InputGroup>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Cidade</label>
                    <Select
                      name="city"
                      id="city"
                      options={cityOptions}
                      icon={FiMap}
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Endereço Complementar</label>
                    <Input name="complementaryAddress" icon={FiMap} />
                  </InputGroup>
                </FormRow>

                <Button styleType="success" style={{ margin: `16px 0` }}>
                  Salvar endereço
                </Button>
              </Form>
            ) : (
              <Form onSubmit={handleFormSubmit} ref={formRef}>
                <FormRow separator>
                  <h1>Dados para telefone</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Tipo</label>

                    <Select name="type" options={typePhone} icon={FiHash} />
                  </InputGroup>

                  <InputGroup>
                    <label>Código de Área</label>
                    <Select name="cod" icon={FiHash} options={typeCodStates} />
                  </InputGroup>

                  <InputGroup>
                    <label>Telefone</label>
                    <InputMask mask="99999-9999" name="phone" icon={FiPhone} />
                  </InputGroup>
                </FormRow>

                <FormRow buttonWrapper>
                  <Button
                    type="submit"
                    loading={buttonLoading}
                    styleType="success"
                    icon={FiSave}
                  >
                    Enviar
                  </Button>
                </FormRow>
              </Form>
            )}
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export default NewNominalCard;
