import { states } from '@helpers/getAllStates';

export const adressType = [
  { value: 'RESIDENTIAL', label: 'RESIDENCIAL' },
  { value: 'COMMERCIAL', label: 'COMERCIAL' },
  { value: 'OTHER', label: 'OUTRO' },
];

export const typePhone = [
  { value: 'RESIDENTIAL', label: 'RESIDENCIAL' },
  { value: 'COMMERCIAL', label: 'COMERCIAL' },
  { value: 'MOBILE', label: 'MÓVEL' },
];

export const statesOption = Object.entries(states).map(item => ({
  value: item[0],
  label: item[1],
}));

export const typeCodStates = [
  { value: "11", label: "São Paulo (11)" },
  { value: "12", label: "São Paulo (12)" },
  { value: "13", label: "São Paulo (13)" },
  { value: "14", label: "São Paulo (14)" },
  { value: "15", label: "São Paulo (15)" },
  { value: "16", label: "São Paulo (16)" },
  { value: "17", label: "São Paulo (17)" },
  { value: "18", label: "São Paulo (18)" },
  { value: "19", label: "São Paulo (19)" },
  { value: "21", label: "Rio de Janeiro (21)" },
  { value: "22", label: "Rio de Janeiro (22)" },
  { value: "24", label: "Rio de Janeiro (24)" },
  { value: "27", label: "Espírito Santo (27)" },
  { value: "28", label: "Espírito Santo (28)" },
  { value: "31", label: "Minas Gerais (31)" },
  { value: "32", label: "Minas Gerais (32)" },
  { value: "33", label: "Minas Gerais (33)" },
  { value: "34", label: "Minas Gerais (34)" },
  { value: "35", label: "Minas Gerais (35)" },
  { value: "37", label: "Minas Gerais (37)" },
  { value: "38", label: "Minas Gerais (38)" },
  { value: "41", label: "Paraná (41)" },
  { value: "42", label: "Paraná (42)" },
  { value: "43", label: "Paraná (43)" },
  { value: "44", label: "Paraná (44)" },
  { value: "45", label: "Paraná (45)" },
  { value: "46", label: "Paraná (46)" },
  { value: "47", label: "Santa Catarina (47)" },
  { value: "48", label: "Santa Catarina (48)" },
  { value: "49", label: "Santa Catarina (49)" },
  { value: "51", label: "Rio Grande do Sul (51)" },
  { value: "53", label: "Rio Grande do Sul (53)" },
  { value: "54", label: "Rio Grande do Sul (54)" },
  { value: "55", label: "Rio Grande do Sul (55)" },
  { value: "61", label: "Distrito Federal (61)" },
  { value: "62", label: "Goiás (62)" },
  { value: "63", label: "Tocantins (63)" },
  { value: "64", label: "Goiás (64)" },
  { value: "65", label: "Mato Grosso (65)" },
  { value: "66", label: "Mato Grosso (66)" },
  { value: "67", label: "Mato Grosso do Sul (67)" },
  { value: "68", label: "Acre (68)" },
  { value: "69", label: "Rondônia (69)" },
  { value: "71", label: "Bahia (71)" },
  { value: "73", label: "Bahia (73)" },
  { value: "74", label: "Bahia (74)" },
  { value: "75", label: "Bahia (75)" },
  { value: "77", label: "Bahia (77)" },
  { value: "79", label: "Sergipe (79)" },
  { value: "81", label: "Pernambuco (81)" },
  { value: "82", label: "Alagoas (82)" },
  { value: "83", label: "Paraíba (83)" },
  { value: "84", label: "Rio Grande do Norte (84)" },
  { value: "85", label: "Ceará (85)" },
  { value: "86", label: "Piauí (86)" },
  { value: "87", label: "Pernambuco (87)" },
  { value: "88", label: "Ceará (88)" },
  { value: "89", label: "Piauí (89)" },
  { value: "91", label: "Pará (91)" },
  { value: "92", label: "Amazonas (92)" },
  { value: "93", label: "Pará (93)" },
  { value: "94", label: "Pará (94)" },
  { value: "95", label: "Roraima (95)" },
  { value: "96", label: "Amapá (96)" },
  { value: "97", label: "Amazonas (97)" },
  { value: "98", label: "Maranhão (98)" },
  { value: "99", label: "Maranhã (99)o" }
].sort((a, b) => a.label.localeCompare(b.label));
