import styled from "styled-components";

interface IAddress {
  disabled: boolean;
}

export const Address = styled.div<IAddress>`
  padding: 8px 16px;

  background-color: #fff;

  box-shadow: 0 0 5px rgba(0, 0, 0, .5);

  border-radius: 8px;

  border: 1px solid transparent;

  display: flex;
  align-items: center;

  gap: 16px;

  margin-top: 16px;

  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  & + * {
    margin-top: 8px;
  }

  > header {
    > svg {
      color: ${props => props.theme.colors.primary};
    }
  }

  > main {
    flex: 1;

    > p {
      font-size: 16px;
    }
  }

  > footer {

  }
`;
