import * as Yup from 'yup';

import { IFormData } from './interface';

export const addressFormValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    addressType: Yup.string().required('Preencha este campo!'),
    zipCode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    number: Yup.string().required('Preencha este campo!'),
    neighborhood: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};

export const formValidation = async (data: {
  [key: string]: string;
}): Promise<void> => {
  const shape = Yup.object().shape({
    type: Yup.string().required('Preencha este campo!'),
    phone: Yup.string()
      .required('Preencha este campo!')
      .length(9, 'Insira um número de telefone completo!')
      .matches(/(?:[2-8]\d{3}-?\d{4}|9[1-9]\d{3}-?\d{4})/, 'Numero de telefone inválido!'),
    cod: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
