import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FC, Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { FiCreditCard, FiDollarSign, FiPlusCircle } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { v4 } from 'uuid';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Button } from '@components/elements/Button';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Textarea } from '@components/elements/Form/Textarea';
import { Money } from '@components/layouts/Money';
import { URLPath } from '@components/layouts/UrlPath';
import { Modal } from '@components/elements/Modal';
import { DataTable } from '@components/elements/Datatable';
import { Checkbox } from '@components/elements/Checkbox';

import { useToast } from '@hooks/toast';

// import { bankApi } from '@services/bankApi';
import api from '@services/bbankApi';

import { consoleLog } from '@helpers/consoleLog';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { Card, CardContent, CardHeader } from '../../Transfer/New/styles';
import { IFormData, IElement, IBilletAmount } from './interfaces';
import { formValidation } from './validations';
import { BottomSheet, RemoveElementButton } from './styles';

const firstId = v4();

export const CreateBillPayments: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [elements, setElements] = useState<IElement[]>([{ id: firstId }]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPortion, setSelectedPortion] = useState<number | undefined>();
  const [billetsAmount, setBilletsAmount] = useState<IBilletAmount[]>([
    { id: firstId, amount: 0 },
  ]);
  const [showBottomSheet, setShowBottomSheet] = useState<boolean | undefined>(
    undefined,
  );

  const handleAddPayment = useCallback(() => {
    const id = v4();

    const updatedState = [...elements, { id }];

    if (updatedState.length > 1) setShowBottomSheet(true);

    setBilletsAmount(oldState => [...oldState, { id, amount: 0 }]);
    setElements(updatedState);
  }, [elements]);

  const handleRemoveElements = useCallback(
    (key: string) => {
      const updatedState = elements.filter(element => element.id !== key);

      if (updatedState.length <= 1) {
        setShowBottomSheet(false);
      }

      setBilletsAmount(oldState =>
        oldState.filter(amount => amount.id !== key),
      );
      setElements(updatedState);
    },
    [elements],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        const parsedData = data.data.filter(item => {
          const objectKeys = Object.keys(item).length;

          if (objectKeys > 2) return true;

          return false;
        });

        const parsedFormData = { ...data, data: parsedData };

        await formValidation(parsedFormData);

        setLoadingRequest(true);

        consoleLog(parsedFormData);

        // await bankApi.post(`/bill-payments`, {
        //   type: data.type,
        //   description: data.description,
        //   barCode: data.barCode,
        //   line: data.line,
        // });

        addToast({
          title: 'Legal!',
          type: 'success',
          message: 'O pagamento foi registrado com sucesso!',
        });

        setLoadingRequest(false);

        formRef.current?.reset();
      } catch (err) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          consoleLog({ errors });

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: 'Ops...',
          type: 'error',
          message:
            'O pagamento não foi registrado! Tente novamente mais tarde...',
        });

        consoleLog(err);
      }
    },
    [addToast],
  );

  const billetsTotalAmount = useMemo(() => {
    return billetsAmount.reduce((acc, cur) => {
      let accValue = acc;

      accValue += cur.amount;

      return accValue;
    }, 0);
  }, [billetsAmount]);

  const dataTableColumns = useMemo(() => {
    return [
      {
        name: 'Parcelas',
        selector: 'portions',
      },
      {
        name: 'Percentual',
        selector: 'percent',
      },
      {
        name: 'Escolher',
        selector: 'chose',
      },
    ];
  }, []);

  const dataTableData = useMemo(() => {
    const portions = [
      5.8,
      7.9,
      10.4,
      12.8,
      15.1,
      17.3,
      18.5,
      19.2,
      21.0,
      22.8,
      24.2,
      26.1,
    ];

    return portions.map((portion, index) => {
      return {
        id: portion,
        portions: index + 1,
        percent: `${portion}%`,
        chose: (
          <Checkbox
            checked={selectedPortion === index + 1}
            onChange={() => setSelectedPortion(index + 1)}
          />
        ),
      };
    });
  }, [selectedPortion]);

  const handleCreditCardClick = useCallback(async () => {
    try {
      const data = formRef.current?.getData() as IFormData;

      const parsedData = data.data.filter(item => {
        const objectKeys = Object.keys(item).length;

        if (objectKeys > 2) return true;

        return false;
      });

      const parsedFormData = { ...data, data: parsedData };

      await formValidation(parsedFormData);

      setModalVisible(true);
    } catch (err) {
      if (err instanceof ValidationError) {
        const errors = getValidationErrors(err);

        consoleLog({ errors });

        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  const creditCardInfos = useMemo(() => {
    if (
      !billetsTotalAmount ||
      !selectedPortion ||
      !dataTableData[selectedPortion - 1].percent
    ) {
      return { totalAmount: 0, portions: 0 };
    }

    const percentage = dataTableData[selectedPortion - 1].percent.replace(
      '%',
      '',
    );
    const fees = (billetsTotalAmount * Number(percentage)) / 100;
    const portions = ((billetsTotalAmount + fees) / selectedPortion).toFixed(2);

    return {
      totalAmount: billetsTotalAmount + fees,
      portions: Number(portions),
    };
  }, [dataTableData, selectedPortion, billetsTotalAmount]);

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format;
  }, []);

  const handleGetPaymentPreview = useCallback(
    async (id: string, value: string, previewType?: string) => {
      try {
        const { data } = await api.get(`/billets/preview/${value}`, {
          params: {
            previewType: previewType || 'boleto-payment',
          },
        });

        if (!data.payment.amount) throw new Error('Amount not available!');

        const { receiverName, receiverTaxId } = data.payment;
        const amount = data.payment.amount / 100;

        setElements(oldState => {
          const findIndex = oldState.findIndex(state => state.id === id);

          const stateToUpdate = oldState[findIndex];
          const updatedState = [...oldState];

          stateToUpdate.billetInfo = {
            amount,
            receiverName,
            receiverTaxId,
          };

          updatedState[findIndex] = stateToUpdate;

          return updatedState;
        });
        setBilletsAmount(oldState => {
          const updatedState = oldState.filter(state => state.id !== id);
          const billetAmount = {
            id,
            amount,
          };

          updatedState.push(billetAmount);

          return updatedState;
        });
      } catch (err: any) {
        addToast({
          title: 'Could not get billet info!',
          type: 'info',
          message: err?.response?.data?.message,
        });
      }
    },
    [addToast],
  );

  return (
    <>
      <AuthModal />

      <URLPath
        paths={['Bbanking', 'Pagamento de contas', 'Novo Pagamento']}
      />

      <Money initVisible />

      <Card>
        <CardHeader>
          <h1>Novo pagamento</h1>

          <Button
            type="button"
            styleType="info"
            loading={loadingRequest}
            icon={FiPlusCircle}
            onClick={handleAddPayment}
          >
            Adicionar novo pagamento
          </Button>
        </CardHeader>

        <CardContent>
          <Form ref={formRef} onSubmit={handleFormSubmit}>
            {elements.map((item, index) => (
              <Fragment key={item.id}>
                <FormRow separator>
                  <h1>
                    {index > 0 ? `Pagamento ${index + 1}` : `Novo pagamento`}
                  </h1>

                  {index > 0 && (
                    <RemoveElementButton
                      onClick={() => handleRemoveElements(item.id)}
                    >
                      Remover pagamento
                    </RemoveElementButton>
                  )}
                </FormRow>

                {item.billetInfo && (
                  <FormRow>
                    <InputGroup>
                      <label>Recebedor</label>
                      <Input
                        name="-"
                        disabled
                        defaultValue={item.billetInfo.receiverName}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Documento do recebedor</label>
                      <Input
                        name="-"
                        disabled
                        defaultValue={item.billetInfo.receiverTaxId}
                      />
                    </InputGroup>
                  </FormRow>
                )}

                <FormRow>
                  <InputGroup>
                    <label>Tipo de pagamento</label>
                    <Select
                      name={`data[${index}].type`}
                      options={[
                        { label: 'Pagamento de taxas', value: 'taxes' },
                        { label: 'Pagamento de contas', value: 'bills' },
                        { label: 'Pagamento de boletos', value: 'billets' },
                      ]}
                      onChange={option => {
                        if (!option) return;

                        const { value } = option;
                        let parsedValue: string;

                        if (value === 'taxes') {
                          parsedValue = 'tax-payment';
                        } else if (value === 'bills') {
                          parsedValue = 'utility-payment';
                        } else {
                          parsedValue = 'boleto-payment';
                        }

                        setElements(oldState => {
                          const element = oldState[index];
                          const updatedState = [...oldState];

                          updatedState[index] = {
                            ...element,
                            type: parsedValue,
                          };

                          return updatedState;
                        });
                      }}
                    />
                  </InputGroup>

                  {item.billetInfo?.amount ? (
                    <InputGroup>
                      <label>Valor do boleto</label>
                      <Input
                        name="-"
                        disabled
                        defaultValue={numberFormat(item.billetInfo.amount)}
                      />
                    </InputGroup>
                  ) : null}
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Tipo de entrada</label>
                    <Select
                      name={`data[${index}].inputType`}
                      options={[
                        { label: 'Código de barras', value: 'barCode' },
                        { label: 'Linha digitável', value: 'line' },
                      ]}
                      onChange={option => {
                        if (!option) return;

                        const { value } = option;

                        setElements(oldState => {
                          const element = oldState[index];
                          const updatedState = [...oldState];

                          updatedState[index] = {
                            ...element,
                            inputType: value,
                          };

                          return updatedState;
                        });
                      }}
                    />
                  </InputGroup>
                </FormRow>

                {item.inputType === 'barCode' && (
                  <FormRow>
                    <InputGroup>
                      <label>Código de barras</label>
                      <Input
                        name={`data[${index}].barCode`}
                        upperCase={false}
                        onBlur={event =>
                          handleGetPaymentPreview(
                            item.id,
                            event.target.value,
                            item.type,
                          )
                        }
                      />
                    </InputGroup>
                  </FormRow>
                )}

                {item.inputType === 'line' && (
                  <FormRow>
                    <InputGroup>
                      <label>Linha digitável</label>
                      <Input
                        name={`data[${index}].line`}
                        upperCase={false}
                        onBlur={event =>
                          handleGetPaymentPreview(
                            item.id,
                            event.target.value,
                            item.type,
                          )
                        }
                      />
                    </InputGroup>
                  </FormRow>
                )}

                <FormRow>
                  <InputGroup textarea>
                    <label>Descrição</label>
                    <Textarea name={`data[${index}].description`} />
                  </InputGroup>
                </FormRow>
              </Fragment>
            ))}

            <FormRow buttonWrapper>
              <Button
                styleType="info"
                icon={FiDollarSign}
                type="submit"
                loading={loadingRequest}
              >
                Saldo em Conta
              </Button>

              <Button
                styleType="success"
                icon={FiCreditCard}
                type="button"
                onClick={handleCreditCardClick}
                loading={loadingRequest}
              >
                Cartão de crédito
              </Button>
            </FormRow>
          </Form>
        </CardContent>
      </Card>

      <Modal
        visible={modalVisible}
        hideButton
        closeButtonText="Cancelar"
        headerText="Escolha o método de parcelamento"
        okButtonText="Pagar!"
        onDismiss={({ visible }) => {
          setModalVisible(visible);
          setSelectedPortion(undefined);
        }}
        onOkClick={() => formRef.current?.submitForm()}
      >
        <DataTable
          columns={dataTableColumns}
          data={dataTableData}
          striped
          pagination={false}
          subHeader={false}
        />
      </Modal>

      <BottomSheet visible={showBottomSheet}>
        <header>
          <h1>Valor total dos boletos:</h1>
        </header>

        <main>
          <span />
          <span>
            {selectedPortion
              ? numberFormat(creditCardInfos.totalAmount)
              : numberFormat(billetsTotalAmount)}
          </span>
        </main>

        {selectedPortion && (
          <footer>
            <span>no cartão em</span>
            <span>
              {`em ${selectedPortion} vezes de ${numberFormat(
                creditCardInfos.portions,
              )}`}
            </span>
          </footer>
        )}
      </BottomSheet>
    </>
  );
};
