import { FormHandles } from '@unform/core';
import { FC, useCallback, useRef, useState, useMemo } from 'react';
import { ValidationError } from 'yup';
import { format } from 'date-fns';
import { FiDollarSign } from 'react-icons/fi';
import { OptionTypeBase } from 'react-select';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Button } from '@components/elements/Button';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { Textarea } from '@components/elements/Form/Textarea';
import { InputMask } from '@components/elements/Form/InputMask';
import { Modal } from '@components/elements/Modal';
import { Money } from '@components/layouts/Money';
import { Select } from '@components/elements/Form/Select';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import { bankApi } from '@services/bankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { consoleLog } from '@helpers/consoleLog';

import { IFormData, IPixInfo, IEventTarget } from './interfaces';
import { keys } from './selectOptions';
import { Card, CardContent, CardHeader, PixConfirmation } from './styles';
import {
  handlePaymentDateValidation,
  handleInfoValidationForm,
  CustomError,
} from './validations';

const NewTransfer: FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const [currentStep, setCurrentStep] = useState(1);
  const [amount, setAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState('');
  const [pixInfo, setPixInfo] = useState<IPixInfo>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [receipt, setReceipt] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [keyType, setKeyType] = useState('');

  const handleInsertValueFormSubmit = useCallback(async (data: IFormData) => {
    try {
      // await handleInsertValueFormValidation(data);

      setAmount(data.amount as number);
      setCurrentStep(2);
    } catch (err: any) {
      if (err instanceof ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  const handleInfoFormSubmit = useCallback(
    async data => {
      try {
        await handleInfoValidationForm(data);
        handlePaymentDateValidation(data.paymentDate);

        setLoadingRequest(true);

        consoleLog(data.pixKey);

        const parsedKey =
          data.keyType === 'cellphone' ? `+55${data.pixKey}` : data.pixKey;

        const { data: responseData } = await bankApi.post(`/pix/keys`, {
          key: parsedKey,
        });

        setPixInfo({ key: responseData.id, ...responseData });
        setLoadingRequest(false);
        setPaymentDate(data.paymentDate);
        setCurrentStep(3);
      } catch (err: any) {
        setLoadingRequest(false);

        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err instanceof CustomError) {
          formRef.current?.setErrors({ paymentDate: err.message });
          return;
        }

        if (err.response) {
          const { status } = err.response;

          if (status === 401) {
            setRefresh(oldState => !oldState);
            return;
          }

          if (err.response.data) {
            if (err.response.data.message) {
              const {
                data: { message },
              } = err.response;

              addToast({
                title: message,
                type: 'info',
              });
            }
          }
        }
      }
    },
    [addToast],
  );

  const handleModal = useCallback(({ visible }) => {
    if (!modalRef.current) return;

    const iframe = document.createElement('iframe');

    iframe.style.display = 'none';

    document.body.appendChild(iframe);

    const iframeContentWindow = iframe.contentWindow;

    if (!iframeContentWindow) return;

    iframeContentWindow.document.open();
    iframeContentWindow.document.write(modalRef.current?.innerHTML);
    iframeContentWindow.close();

    iframeContentWindow.focus();
    iframeContentWindow.print();

    document.body.removeChild(iframe);

    setCurrentStep(1);
    setModalVisible(visible);
  }, []);

  const handlePaymentFormSubmit = useCallback(
    async (data, { reset }) => {
      try {
        setLoadingRequest(true);

        if (!pixInfo?.key) {
          addToast({
            title: 'Chave pix não informada!',
            type: 'info',
          });
          return;
        }

        const { data: responseData } = await bankApi.post('/pix', {
          key: pixInfo.key,
          payment: {
            value: amount * 100,
          },
        });

        await api.post('/financing/reports', {
          clientId: user.id,
          operationValue: amount,
          relation: 'Transferência PIX',
          operationDate: format(new Date(), 'dd/MM/yyyy'),
        });

        setLoadingRequest(false);

        if (responseData.receipt) {
          setReceipt(responseData.receipt);
          setModalVisible(true);
        } else {
          setCurrentStep(1);
          addToast({
            title: 'Transferência feita com sucesso!',
            type: 'success',
          });
        }

        setTimeout(() => {
          reset();
        }, 100);
      } catch (err: any) {
        setLoadingRequest(false);

        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              const {
                data: { message },
              } = err.response;

              addToast({
                title: message,
                type: 'info',
              });
            }
          }
        }
      }
    },
    [amount, user, pixInfo, addToast],
  );

  const handlers = useMemo(
    () => [
      handleInsertValueFormSubmit,
      handleInfoFormSubmit,
      handlePaymentFormSubmit,
    ],
    [
      handleInsertValueFormSubmit,
      handleInfoFormSubmit,
      handlePaymentFormSubmit,
    ],
  );

  const handleKeyTypeFieldChange = useCallback(
    (option: OptionTypeBase | null) => {
      if (!option) return;

      consoleLog(option.value);

      setKeyType(option.value);
    },
    [],
  );

  const masks = useMemo((): Record<string, string> => {
    return {
      person: '999.999.999-99',
      legalPerson: '99.999.999/9999-99',
      cellphone: '(99) 99999-9999',
      random: '?????????????????????????????????????????????????????',
      email: '?????????????????????????????????????????????????????',
    };
  }, []);

  return (
    <>
      <AuthModal refresh={refresh} />

      <Modal
        visible={modalVisible}
        headerText="Comprovante de transferência"
        hideButton
        okButtonText="Fazer o download"
        closeButtonText="Fechar"
        onOkClick={handleModal}
        hide={{
          closeButton: true,
          xButton: true,
        }}
        ref={modalRef}
      >
        <p style={{ whiteSpace: 'pre-line' }}>{receipt}</p>
      </Modal>

      <Row>
        <Money />
      </Row>

      <Row>
        <URLPath paths={['Transferencia', 'Nova Transferencia']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova transferencia</h1>
          </CardHeader>

          <CardContent>
            <Form
              onSubmit={handlers[currentStep - 1]}
              ref={formRef}
              progressBar={{ active: true, steps: 3, currentStep }}
            >
              {currentStep === 1 && (
                <>
                  <FormRow separator>
                    <h1>Primeiro de tudo, insira o valor!</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor</label>
                      <InputCurrency name="amount" icon={FiDollarSign} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button type="submit" styleType="success">
                      Avançar
                    </Button>
                  </FormRow>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <FormRow separator>
                    <h1>Continue! Estamos quase lá...</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tipo da chave</label>
                      <Select
                        name="keyType"
                        options={keys}
                        onChange={handleKeyTypeFieldChange}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Chave PIX</label>
                      <InputMask
                        name="pixKey"
                        mask={masks[keyType]}
                        formatChars={{
                          '9': '[0-9]',
                          '?': '[\\s\\S]*',
                        }}
                        noUnmask={keyType === 'email' || keyType === 'random'}
                        onKeyUp={event => {
                          if (keyType !== 'email' && keyType !== 'random') {
                            return;
                          }

                          const target = event.target as IEventTarget;
                          const { value } = target;

                          const parsedValue = value?.replaceAll(' ', '');

                          target.value = parsedValue;
                        }}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data para pagamento</label>
                      <InputMask
                        name="paymentDate"
                        mask="99/99/9999"
                        noUnmask
                        defaultValue={format(new Date(), 'dd/MM/yyyy')}
                        readOnly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Mensagem</label>
                      <Textarea
                        name="message"
                        rows={10}
                        placeholder="Mensagem aqui..."
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button
                      type="button"
                      styleType="warning"
                      onClick={() => setCurrentStep(oldState => oldState - 1)}
                    >
                      Voltar
                    </Button>
                    <Button
                      type="submit"
                      styleType="success"
                      loading={loadingRequest}
                    >
                      Avançar
                    </Button>
                  </FormRow>
                </>
              )}

              {currentStep === 3 && (
                <PixConfirmation>
                  <header>
                    <h1>Transferindo...</h1>

                    <strong>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(amount || 0)}
                    </strong>

                    <p>
                      para&nbsp;
                      <strong>{pixInfo?.name || '...'}</strong>
                    </p>
                  </header>

                  <main>
                    <div className="datePicker">
                      <span>Quando</span>

                      <strong>
                        {paymentDate === format(new Date(), 'dd/MM/yyyy')
                          ? 'Agora'
                          : paymentDate}
                      </strong>
                    </div>

                    <div>
                      <span>Chave</span>

                      <strong>{pixInfo?.id || '...'}</strong>
                    </div>

                    <div>
                      <span>Tipo de chave</span>

                      <strong>{pixInfo?.type || '...'}</strong>
                    </div>

                    <div>
                      <div>
                        <span>
                          {pixInfo?.ownerType === 'naturalPerson'
                            ? 'CPF'
                            : 'CNPJ'}
                        </span>
                        <span>{pixInfo?.taxId || '...'}</span>
                      </div>

                      <div>
                        <span>Instituição financeira</span>
                        <span>{pixInfo?.bankName || '...'}</span>
                      </div>
                    </div>
                  </main>

                  <footer>
                    <Button
                      type="button"
                      styleType="warning"
                      onClick={() => setCurrentStep(oldState => oldState - 1)}
                    >
                      Voltar
                    </Button>
                    <Button
                      type="submit"
                      styleType="info"
                      loading={loadingRequest}
                    >
                      Confirmar...
                    </Button>
                  </footer>
                </PixConfirmation>
              )}
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewTransfer };
