import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import {
  FiDollarSign,
  FiDownload,
  FiEye,
  FiMoreHorizontal,
  FiPlusCircle,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';

import { Badge } from '@components/elements/Badge';
import { DataTable } from '@components/elements/Datatable';
import { Dropdown } from '@components/elements/Dropdown';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

interface IState {
  id: string;
  balance: number;
  user: {
    name: string;
  };
}

interface IDataTableData {
  id: string;
  search: string;
  balance: string;
  actions?: JSX.Element;
}

const AllUserBalances: FC = () => {
  const { push } = useHistory();

  const [users, setUsers] = useState<Array<IState>>([]);
  const [loadingPage, setLoadingPage] = useState(true);

  const dataTableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Saldo',
        selector: 'balance',
      },
      // {
      //   name: 'Ações',
      //   selector: 'actions',
      // },
    ];
  }, []);

  // const handlePushToUserView = useCallback(
  //   (id: string) => {
  //     push(`/users/view/${id}`);
  //   },
  //   [push],
  // );

  const dataTableData = useMemo<Array<IDataTableData>>(() => {
    return users.map(item => {
      return {
        id: item.id,
        search: item.user.name,
        balance: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(item.balance),
        // actions: (
        //   <TableButtonContainer>
        //     <TableButton
        //       styleType="info"
        //       onClick={() => handlePushToUserView(item.id)}
        //       icon={FiEye}
        //     />
        //     <TableButton
        //       styleType="success"
        //       icon={FiDollarSign}
        //       onClick={() => handlePushToTaxesView(item.id)}
        //     />
        //   </TableButtonContainer>
        // ),
      };
    });
  }, [users]);

  useEffect(() => {
    async function loadUsers() {
      const { data } = await api.get<any>('/users/balance/all');

      setUsers(data);
      setLoadingPage(false);
    }

    const timer = setTimeout(() => {
      loadUsers();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleExportToExcel = () => {
    if (!users || users.length === 0) {
      console.error('Não há dados para exportar.');
      return;
    }

    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(users);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados de Usuários');

    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'dados_saldos_usuarios.xlsx');

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      {loadingPage ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Usuários', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Listagem</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#782273' }}
                  icon={FiMoreHorizontal}
                  isDisabled={users.length === 0}
                >
                  <li>
                    <button type="button" onClick={() => push('/users/new')}>
                      <FiPlusCircle />
                      Adicionar novo usuário
                    </button>
                  </li>
                  <li>
                    <button type="button" onClick={handleExportToExcel}>
                      <FiDownload />
                      Exportar dados para Excel
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={dataTableColumns}
                  data={dataTableData}
                  filterPlaceholder="Filtrar por nome"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { AllUserBalances };
