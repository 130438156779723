import { FC, useMemo, useState } from 'react';
import Datatable, {
  RowRecord,
  Selector,
  TableProps,
} from 'react-data-table-component';

import { FilterInput } from './FilterInput';

interface ITableProps extends TableProps {
  filterPlaceholder?: string;
}

const DataTable: FC<ITableProps> = ({ data, filterPlaceholder, ...rest }) => {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo<JSX.Element>(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterInput
        filterPlaceholder={filterPlaceholder}
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle, filterPlaceholder]);

  const filteredItems = useMemo(() => {
    const searchColumns = rest.columns.map(
      item => item.selector,
    ) as Selector<RowRecord>[];

    const parsedRowRecords = data.map(record => ({
      columns: searchColumns,
      record,
    }));

    const unk = parsedRowRecords.reduce<RowRecord[]>((acc, cur) => {
      const { columns, record } = cur;
      const matches: RowRecord[] = [];

      columns.forEach(column => {
        const columnRecord = record[column as string];

        if (typeof columnRecord === 'string' && matches.length === 0) {
          const match = columnRecord
            .toLowerCase()
            .includes(filterText.toLowerCase());

          if (match) {
            matches.push(record);
          }
        }
      });

      acc.push(...matches);

      return acc;
    }, []);

    return unk;
  }, [filterText, data, rest.columns]);

  return (
    <Datatable
      data={filteredItems}
      {...rest}
      pagination={rest.pagination !== undefined ? rest.pagination : true}
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader={rest.subHeader !== undefined ? rest.pagination : true}
      subHeaderComponent={
        !rest.subHeaderComponent
          ? subHeaderComponentMemo
          : rest.subHeaderComponent
      }
    />
  );
};

export { DataTable };
