import { useContextSelector } from 'use-context-selector';

import { AuthContext } from '../contexts/auth';

export interface IUser {
  id: string;
  email: string;
  name: string;
  token: string;
  document: string;
  role?: string;

  address?: string;
  city?: string;
  phone?: string;
  state?: string;

  zipcode?: string;
}

interface IAuthContext {
  signIn(
    document: string,
    password: string,
  ): Promise<{ ok: boolean; type?: 'pendente' | 'inativo' }>;
  signOut(): void;
  handleCompleteData(): void;
  loading: boolean;
  user: IUser;
  completedData: boolean;
}

export function useAuth(): IAuthContext {
  const loading = useContextSelector(
    AuthContext,
    authContext => authContext.loading,
  );
  const signIn = useContextSelector(
    AuthContext,
    authContext => authContext.signIn,
  );
  const signOut = useContextSelector(
    AuthContext,
    authContext => authContext.signOut,
  );
  const handleCompleteData = useContextSelector(
    AuthContext,
    authContext => authContext.handleCompleteData,
  );
  const user = useContextSelector(AuthContext, authContext => authContext.user);
  const completedData = useContextSelector(
    AuthContext,
    authContext => authContext.completedData,
  );

  return {
    loading,
    signIn,
    signOut,
    user,
    completedData,
    handleCompleteData,
  };
}
